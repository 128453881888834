<template>
  <section>
    <div class="richtext">
      <p>
        The saltwater dosing pump can be used for the transportation of strong
        corrosive media such as sulfuric acid, hydrochloric acid and nitric
        acid.
      </p>
      <p><br /></p>
      <h2><strong>Characteristics Of Saltwater Dosing Pump</strong></h2>
      <p><br /></p>
      <p>
        The saltwater dosing pump has the working performance of a piston pump
        and the sealing characteristics of a diaphragm pump. The diaphragm is of
        good quality, simple in design. The operation of the entire pump is
        simple and easy. The flow range of the mechanical diaphragm dosing pump
        can be from 0-100% to the maximum. The whole process is dynamically
        adjusted. It has the anti-corrosion treatment parts and heavy-duty
        plastic cavity which can be used for heavy corrosive liquids. What’s
        more, the long-life metal and fabric reinforced diaphragms are used as
        fluid precision dosing and ideal equipment for dosing.
      </p>
      <p><br /></p>
      <h2><strong>Application Fields Of Saltwater Dosing Pump</strong></h2>
      <p><br /></p>
      <p>
        Saltwater dosing pumps have now been widely used in various fields
        including the pharmaceutical, food and beverage and petrochemical
        industries, and are responsible for the dosing tasks of highly
        corrosive, toxic, high-viscosity and high-pressure media in the process.
        It is suitable for the dosing of various preservatives, anticrustator,
        bactericidal agents, flocculants, dispersants and other water treatment
        agents, and also suitable for the dosing of other acid and alkali
        liquids. The dosing barrel is generally made of glass fiber reinforced
        plastic composite material. After the workshop wastewater is collected,
        it is lifted with a corrosion-resistant pump, and then injected into the
        pre-filter to filter out large suspended solids and sand particles to
        prevent them from being deposited in the tubular catalytic oxidation
        device and affecting the catalytic effect.
      </p>
      <p><br /></p>
      <h2><strong>Development Of Saltwater Dosing Pump</strong></h2>
      <p><br /></p>
      <p>
        After more than half a century of practical application and
        technological improvement, both the performance and operation of the
        saltwater dosing pump have been continuously improved. Now the function
        of the saltwater dosing pump has been in line with international
        standards.
      </p>
    </div>
  </section>
</template>
<script>
export default {
  title: "SaltWater Dosing Pump",
}
</script>